.card {
    .card-body {
        .mintOrderTitle {
            margin-bottom: 55px;
        }
        .metamastConnection {
            cursor: pointer;
            display: inline-block;
            background-color: $red;
            padding: 10px;
            border-radius: 18px;
            line-height: 1;
            vertical-align: middle;
            font-weight: 400;
            color:$white
        }
    }
}
