.nftComponent{
    width: 100%;
    .newNFTrow{
        display: flex;
        justify-content: space-around;
        padding: 15px 0;
        border-bottom: 1px solid #0805052b;
    }
    .nftCard{
        width:24%;
        float: left;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        background-color: #f9f9f9;
        margin: 6px;
        .wrapper{
            .wrapperImag{
                height: 250px;
                width: 100%;
            }
            .body{
                padding: 10px;
                .dflexJCSB{
                    display: flex;
                    justify-content: space-between;
                }
            }
      
        // text-align: center;

          
            .wrapperUserImag{
                height: 70px;
                width: 70px;
                border-radius: 50px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
                background-color: #f9f9f9;
                padding: 5px;
            }
        }

    }
    .addNftBtn{
        height: 60px;
        text-align: right;
        padding-right: 20px;
        .addNFTimg{
            height: 50px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
            background-color: #f9f9f9;
            border-radius: 30px;
            padding: 9px;
            cursor: pointer;
            // width: 50px;
        }
    }
}