/* Auth */

.auth {
	
	height: 100%;
	background: #000000;
	.login-half-bg {
		background: url("../../../images/auth/login-bg.jpg");
		background-size: cover;
	}
	
	.register-half-bg {
		background: url("../../../images/auth/register-bg.jpg");
		background-size: cover;
	}
	&.lock-full-bg {
		background: url("../../../images/auth/lockscreen-bg.jpg");
		background-size: cover;
	}
	.lock-profile-img {
		width: 90px;
		height: 90px;
		border-radius: 100%;
	}
	.auth-form-light {
		// background: $white;
		background: #9b9b9ba1;
		border-radius: 40px;
		position: relative;

		select {
			color: $input-placeholder-color;
		}
		.input-group {
			.form-control {
				&:focus,
				&:active {
					border-color: $border-color;
				}
			}
		}
		.loginHeader{
			text-align: center;
			padding: 20px 0
		}
		.btn-icicb{
			background: #9f834d;
			color: #ffffff;
		}
		.registerText{
			text-align: center;
		}
		.signupSelect{
			.css-yk16xz-control{
				border-radius: 30px !important;
				padding: 6px !important;
			}
			.css-1laao21.a11yText{
				border-radius: 30px !important;
				padding: 6px !important;
			}
		}
		.alert-login{
			text-align: center;
			color: #f44336;
			margin-bottom: 20px;
		}
		
	}
	.auth-form-transparent {
		background: transparent;
		.form-control,
		.input-group-text {
			border-color: theme-color(secondary);
			color: white !important;
			&:focus,
			&:active {
				border-color: theme-color(secondary);
			}
		}
		select {
			outline-color: theme-color(secondary);
		}
	}
	&.auth-img-bg {
		padding: 0;
		.auth-form-transparent {
			@media (min-width:768px) {
				width: 55%;
				margin: auto;
			}
		}
	}
	.brand-logo {
		margin-bottom: 2rem;
		background-color: #14010140;
		text-align: center;
		border-radius: 100px;
		box-shadow: 2px 3px 25px 10px #a0834d;
		height: 160px;
		width: 160px;
		position: absolute;
		transform: translate(-50%, -50%);
		top: -105px;
		left: 50%;
	
		img {
			width: 120px;
			height: 118px;
			margin-top: 35px;
			padding: 10px;
		}
		
	}
	form {
		.form-group {
			margin-bottom: 1.5rem;
			label {
				font-size: .8125rem;
			}
			.form-control {
				background: transparent;
				border-radius: 0;
				font-size: .9375rem;
				border-radius: 33px;
				padding: 15px 20px !important;
				color: white;
			}
		}
		.auth-form-btn {
			padding: 1rem 3rem;
			line-height: 1.5;
			border-radius: 33px;
		}
		.auth-link {
			font-size: $default-font-size;
			&:hover {
				color: initial;
			}
		}
	}
}