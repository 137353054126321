
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');
.sidebar{
    .custom-logo{
        .heading{
            // font-size: 60px;
            // margin-top: 60px;
            color: #ebce50;
            display: inline-block;
            font-size: 30px;
            line-height: 1;
            vertical-align: middle;
            font-weight: 600;
            font-family: 'Rubik';
            letter-spacing: 2px;
            margin: 0;
        }
        .customLogo{
            width: 55px !important;
            height: 55px !important;
            margin-right: 14px !important;
            background-color: #ffffff54;
            border-radius: 50px;
            padding: 11px;
        }
    }
}