
.nft-card-list-component{

    /* Chrome, Safari, Edge, Opera */
    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    // -webkit-appearance: none;
    // margin: 0;
    // }

    // input[type=number]::-webkit-inner-spin-button, 
    // input[type=number]::-webkit-outer-spin-button { 
    // -webkit-appearance: none; 
    // margin: 0; 
    // }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
    /* Firefox */
    // input[type=number] {
    // -moz-appearance: textfield;
    // }

    .custom-card-row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12.5px;
        margin-left: -12.5px;
        justify-content: space-around;
        // justify-content: flex-start;
        .nft-card-component{
            flex: 0 0 20%;
            max-width: 18%;
            border-radius: 10px;
            position: relative;
            height: 265px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
            // margin-top: 20px;
            margin-bottom: 20px;
            .image{
                position: absolute;
                height: 100%;
                img{
                    border-radius: 10px;
                    width: 100%;

                }
            }
            .nft-card-body{
                margin-top: 20px;
                position: absolute;
                width: 93%;
                bottom: 10px;
                background: #00000052;
                color: #ffffff;
                text-align: center;
                left: 10px;
                text-transform: capitalize;
                padding: 2px 15px;
                border-radius: 0 0 12px 12px;
                .micro-section{
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                .description {
                    p{
                        margin: 0;
                        padding: 0;
                    }
                }
                .status{
                    margin-top: 10px;
                    margin-bottom: 10px;
                    width: 100%;
                    .non-listed{
                        background-color: pink;
                        padding: 10px;
                        width: 100%;
                        border-radius: 25px;
                        border: none;
                    }
                    .listed{
                        background-color: red;
                        padding: 10px;
                        width: 100%;
                        border-radius: 25px;
                        border: none;
                    }
                }
                .name{
                    // position: absolute;
                    // bottom: 27px;
                    // background: #0000001f;
                    // color: #ffffff;
                    // text-align: center;
                    // left: 10px;
                    // text-transform: capitalize;
                    // padding: 2px 15px;
                }

               
            }
            &:hover{
                box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
                opacity: .7;
            }
         
        }
    }

    .nft-card-list-option{
        text-align: right;
        select{
            border: none;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
            background-color: #0e0b0b94;
            margin-right: 10px;
            padding: 6px 20px;
            border-radius: 5px;
            color: #ffffff;
            &:before {
                content: "yyy";
                color: red;
                padding: 30px;

            }
            &:after {
                content: "* * *";
                color: green;
                padding: 30px;
            }
        }
    }


}

// .nft-card-component{
//     .nft-modal-parent{
//         .modalCrossBtnTest{
//             padding: 50px ;
//             border-radius: 30px !important;
//         }
//     }
// }