.orderComponent{
    width: 100%;

    .newUrow{
        display: flex;
        justify-content: space-around;
        padding: 15px 0;
        border-bottom: 1px solid #0805052b;
    }
    // .totlaOrder{
    //     color: #392bf591;
    // }
    // .orderCard{
    //     width:24%;
    //     float: left;
    //     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    //     background-color: #f9f9f9;
    //     margin: 6px;
    //     border-radius: 15px;
    //     text-align: center;
    //     padding: 30px 0;
    //         .wrapperImag{
    //             height: 100%;
    //             width: 100%;
    //             border-radius: 15px 15px 0 0 ;
    //     }
    //     .body{
    //         padding: 14px 12px;
    //     }
    // }
}