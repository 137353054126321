.userComponent{
    width: 85%;
    .adminUser{
        background-color: #ffffff;
        padding: 4px;
        box-shadow: 4px 2px 7px 2px rgba(0, 0, 0, 0.16);
        .image{
            img{
                height: 100%;
                width: 100%;
            }
            .email{
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    .totalCount{
        color: #04765094;
    }
}

