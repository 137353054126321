.mint-component{
    .mint-nft-card{
        .web3-connect-discon{
            padding-top: 17px;
            padding-left: 10px;
            .web3-connected{
                background-color: #008000ba;
                padding: 6px 16px;
                border-radius: 17px;
                color: #ffffff;
                cursor: pointer;
                &:before {
                    content: "connected";
                  }
            }
            .web3-not-connected{
                background-color: #ff0000d1;
                padding: 6px 16px;
                border-radius: 17px;
                color: #ffffff;
                cursor: pointer;
                &:before {
                    content: "not connected";
                  }
            }
        }
    }
}
.customModelPlaceBid{
    .css-79elbk{
        width: 100%;
        position: static;
        background: transparent;
        .css-df17o1{
            background-color: #ffffff00;
        }
    }
}