.AONTNComponent{
    width: 100%;
    .AONOT{
        width:24%;
        float: left;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        background-color: #f9f9f9;
        margin: 6px;
        padding: 40px 15px;
        border-radius: 50px 0 50px 0;
    }
}